<template>
  <v-row>
    <!-- Start Single Address  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      v-for="(address, i) in addressContent"
      :key="i"
    >
      <div class="rn-address" :class="address.isAddress">
        <div class="icon" v-html="iconSvg(address.icon)"></div>
        <div class="inner">
          <h4 class="heading-title">{{ address.title }}</h4>
          <p>
            <a :href="address.to1">{{ address.info1 }}</a>
          </p>
          <p>
            <a :href="address.to2">{{ address.info2 }}</a>
          </p>
        </div>
      </div>
    </v-col>
    <!-- End Single Address  -->
  </v-row>
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        addressContent: [
          {
            icon: "headphones",
            title: this.$t('phones'),
            info1: "+55 (11) 98061-4377 - Brasil",
            info2: "+351 916 709 833 - Portugal",
            to1: "tel:+55 11 980614377",
            to2: "tel:+351 916 709 833",
          },
          {
            icon: "mail",
            title: this.$t('emails'),
            info1: "contato@simoninisoftware.com.br",
            info2: "contacto@simoninisoftware.pt",
            to1: "mailto:contato@simoninisoftware.com.br",
            to2: "",
          },
          {
            icon: "map",
            title: this.$t('offices'),
            info1: this.$t('shortlyAddressBrazil'),
            info2: this.$t('shortlyAddressPortugal'),
            isAddress: "addres-last",
          },
        ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>

<style lang="scss">
  .addres-last {
    p {
      pointer-events: none;
    }
  }
</style>
